<template>
  <div class="button-wrapper">
    <button class="custom-button" @click="handleClick" :disabled="disabled">
      <slot></slot>
    </button>
  </div>
</template>

<script>
export default {
  name: "CallToActionButton",
  emits: ["buttonClick"],
  props: {
    disabled: {
      required: false,
    },
  },

  methods: {
    handleClick(event) {
      this.$emit("buttonClick", event);
    },
  },
};
</script>

<style scoped>
.custom-button {
  background: var(--primary-color);
  box-sizing: border-box;
  border: 1px var(--primary-color) solid;
  min-height: 42px;
  border-radius: 10px;
  color: #fff;
  padding: 10px;
  max-width: 500px;
  padding: 10px;
}

.custom-button:not(:disabled):hover {
  background: #357db5;
}

:disabled {
  cursor: not-allowed;
  opacity: 0.4;
}

@media screen and (max-width: 576px) {
  .custom-button {
    max-width: 200px;
  }
}
</style>
1