import store from "@/store/storeIndex";
import { getPlans } from "@/api/subscriptions/subscriptionsApi";

export const getPlansMixin = {
  created() {
    let plans = store.getters['subscription/getAllPlans'];
    if(plans.length === 0) {
      getPlans().then(response => {
        store.dispatch('subscription/setPlans', response.data.data);
      })
    }
  }
}