import { createApp } from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import router from "@/router/routerIndex.js";
import store from "@/store/storeIndex.js";
import { registerComponents } from "@/components.js";
import "@/assets/styles/styles.css";

loadFonts();

const app = createApp(App);
registerComponents(app);
app.use(vuetify);
app.use(router);
app.use(store);
app.mount("#app");

