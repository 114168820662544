export const actions = {

  setPlans({ commit }, plans ) {
    commit("setPlans", plans);
  },

  setPlan({ commit }, plan) {
    commit("setPlan", plan);
  },

  togglePlanStatusInState({ commit }, planId) {
    commit("togglePlanStatusInState", planId);
  },

};
