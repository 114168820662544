const actions = {
  activateLoader({ commit }) {
    commit("activateLoader");
  },

  deactivateLoader({ commit }) {
    commit("deactivateLoader");
  },
};

const mutations = {
  activateLoader(state) {
    state.isLoading++;
  },

  deactivateLoader(state) {
    state.isLoading <= 0 ? (state.isLoading = 0) : state.isLoading--;
  },
};

const getters = {
  getIfIsLoading: (state) => {
    return state.isLoading;
  },
};

const state = {
  isLoading: 0,
};

const loading = {
  state,
  actions,
  getters,
  mutations,
  namespaced: true,
};

export default loading;
