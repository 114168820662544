import { getAuthenticatedUser, signOut } from "@/api/auth.js";

export const actions = {
  changeAuthState({ commit }, newAuthState) {
    commit("setIsUserAuthenticated", newAuthState);
  },

  async setUserInfo({ commit }) {
    const userInfo = await getAuthenticatedUser();
    commit("setUserInfo", userInfo);
  },

  clearUserInfo({ commit }) {
    commit("setUserInfo", {});
  },

  async logout({ dispatch }) {
    return signOut().then((data) => {
      if (data) {
        localStorage.clear();
        dispatch('clearUserInfo');
        dispatch('changeAuthState');
      }
    });
  }
};
