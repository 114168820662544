import { Api } from "@/api/api.js";

export const getAllDoctors = () => {
  return Api.get("/doctors").then((response) => {
    return response.data;
  });
};

export const getDoctorById = (doctorId) => {
  return Api.get(`/doctors/${doctorId}`).then((response) => {
    return response.data;
  });
};
