import { createStore } from "vuex";
import user from "./modules/user";
import practice from "./modules/practice";
import loading from "./modules/loadingIndex.js";
import subscription from "./modules/subscription"

const store = createStore({
  modules: {
    user,
    practice,
    loading,
    subscription,
  },
});

export default store;
