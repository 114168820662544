<template>
  <v-dialog
    persistent
    class="upload-dialog"
    activator="parent"
    v-model="popupState"
  >
    <v-card class="pa-5 card-container">
      <div>
        <h2>Add Addon to practice</h2>
        <v-card-text>
          <div>
            Adding Addons will increase the practice ability to consume features!
          </div>
          <div style="margin-top:15px">
            <h4>Choose addon</h4>
            <div class="d-flex plans-container">
              <div 
                v-for="addon in availableAddons" 
                :key="addon.id" 
                @click="selectAddon(addon.id)"
                class="plan" 
                :class="{'selected': addon.id === selectedAddonId}"
              >
                {{addon.name}}<br>renew every {{addon.renewal_value}} {{addon.renewal_type}}
              </div>
            </div>
          </div>
          <div style="margin-top: 20px">
            Start Date
            <ControlledInputField inputName="StartDate" inputFor="startDate">
              <input
                type="date"
                id="startDate"
                v-model="startDate"
              />
            </ControlledInputField>
          </div>
        </v-card-text>
      </div>
      <div class="d-flex justify-content-end align-items-center">
        <div class="d-flex">
          <CallToActionButton class="mr-3 grey" @buttonClick="handleCancel">
            Cancel
          </CallToActionButton>
          <CallToActionButton
            @buttonClick="handleSubscribe"
          >
            Proceed
          </CallToActionButton>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {

  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    currentPlanId: {
      type: Number,
      required: false
    },
    availableAddons: {
      required: true,
    }
  },

  data() {
    return {
      selectedAddonId: this.currentAddonId,
      startDate: null,
    }
  },

  methods: {
    handleCancel() {
      this.popupState = false;
    },
    handleSubscribe() {
      this.$emit("subscribe", this.selectedAddonId, this.startDate);
      this.popupState = false;
    },
    selectAddon(addonId) {
      this.selectedAddonId = addonId;
    }
  },

  computed: {
    popupState: {
      get() {
        return this.isOpen;
      },
      set(newValue) {
        this.$emit("update:isOpen", newValue);
      },
    },
  }
  
}
</script>
<style scoped>
  .plans-container{
    flex-wrap: wrap;
  }
  .plan {
    user-select: none;
    border: 1px solid grey;
    padding: 10px;
    max-width: 165px;
    cursor: pointer;
    margin: 10px 10px 0px 0px;
  }
  .selected {
    border: 2px solid var(--primary-color);
  }
  .card-container {
    max-height: 500px;
    overflow: auto !important;
  }
</style>