<template>
  <div class="plans-container">
    <ListLayout>
      <template #list>
        <v-card class="w-100" elevation="5">
          <v-table
            class="mb-0 text-center no-scrollbar"
            fixed-header
          >
            <template v-slot:default>
              <thead>
                <tr>
                  <th>Product Name</th>
                  <th>Quantity</th>
                  <th>Price</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(product, index) in purchaseList" :key="index">
                  <td><input type="text" v-model="product.name" class="product-data" placeholder="Dentiflow Standard"></td>
                  <td><input type="number" v-model="product.quantity" class="product-data" placeholder="1"></td>
                  <td><input type="number" v-model="product.price" class="product-data" placeholder="13"></td>
                  <td class="table-data">
                    <button @click="deleteProduct(index)"><v-icon color="red">fas fa-xmark</v-icon></button>
                    <button @click="addNewProductRow()"><v-icon color="blue">fas fa-plus</v-icon></button>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-table>
        </v-card>
      </template>
    </ListLayout>
    <div style="margin-bottom: 24px">
      <div class="form-actions">
        <div v-if="checkoutUrl" class="copy-section">
          <CallToActionButton @click="copyCheckoutUrl">Copy Link</CallToActionButton>
          <span v-if="checkoutUrlCopied" style="color: green;">Copied to clipboard</span>
        </div>
        <CallToActionButton @click="getLink" :disabled="isSubmitting || !isListValid()">
          <span v-if="isSubmitting" style="color: black;">Generating Link...</span>
          <span v-else style="color: white;">Get Link</span>
        </CallToActionButton>
      </div>
    </div>
    <div class="plan-header">
      <div class="plan-content d-flex" style="border-bottom:1px solid #c5c5c5">
        <div><strong>Plans</strong></div>
        <div></div>
        <div class="w-100">
            <div><strong>Active?</strong></div>
            <div class="help-info">if disabled, users on plan will loose subscription</div>
        </div>
        <div class="w-100">
            <div><strong>Available?</strong></div>
            <div class="help-info">if disabled, plan will not be displayed in system</div>
        </div>
      </div>
    </div>
    <div v-for="plan in plans" :key="plan.id">
      <div class="plan-content d-flex">
        <div>{{plan.name}}</div>
        <div>Renew Every {{plan.renewal_value}} {{plan.renewal_type}}</div>
        <div class="w-100 d-flex">
          <v-switch
            color="primary"
            :label="plan.is_active.toString()"
            v-model="plan.is_active"
            @change="initTogglePlanStatus(plan.id, plan.is_active)"
            hide-details
          ></v-switch>
        </div>
        <div class="w-100 d-flex">
          <v-switch
            color="primary"
            :label="plan.is_available.toString()"
            v-model="plan.is_available"
            @change="initTogglePlanAvailability(plan.id, plan.is_available)"
            hide-details
          ></v-switch>
        </div>
      </div>
    </div>
  </div>
  <ConfirmationDialogue
    cancellationText="Cancel"
    :confirmationText="newPlanStatus ? 'Activate' : 'Deactivate'"
    @success="togglePlanStatus"
    @canceled="togglePlanStatusInLocalState"
    v-model:popup="showConfirmationPopup"
  >
    <template #title>
      {{ newPlanStatus ? "Activate" : "Deactivate" }}
      Plan?
    </template>
    <template #subtitle
      >Are you sure you want to
      {{ newPlanStatus ? "activate" : "deactivate" }} this
      plan? {{newPlanStatus ? "This plan will be available for users to subscribe to" : "make sure there are no users subscribed to this plan before deactivating it."}}
    </template>
  </ConfirmationDialogue>
  <ConfirmationDialogue
    cancellationText="Cancel"
    :confirmationText="newPlanAvailability ? 'Make Available' : 'Hide'"
    @success="togglePlanAvailability"
    @canceled="togglePlanAvailabilityInLocalState"
    v-model:popup="showAvailabilityConfirmationPopup"
  >
    <template #title>
      {{ newPlanAvailability ? "Make Available" : "Hide" }}
      Plan?
    </template>
    <template #subtitle
      >Are you sure you want to
      {{ newPlanAvailability ? "Make Available" : "Hide" }} this
      plan? {{newPlanAvailability ? "This plan will be available for users to subscribe to" : "Users will no longer see this plan but previous users on plan can still be subscribed."}}
    </template>
  </ConfirmationDialogue>
  <InformationDialogue
    :confirmationText="'OK'"
    v-model:popup="showErrorPopup"
  >
    <template #title>
      Error changing plan status
    </template>
    <template #subtitle>
      Unable to deactivate plan there are users subscribed to it.
    </template>
  </InformationDialogue>
</template>

<script>

import { getPlans, togglePlanStatusByPlanId, togglePlanAvailabilityByPlanId, generate2CheckoutUrl } from '../api/subscriptions/subscriptionsApi';
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Plans",

  data() {
    return {
      showConfirmationPopup: false,
      showAvailabilityConfirmationPopup: false,
      currentPlanId: null,
      newPlanStatus: false,
      newPlanAvailability: false,
      showErrorPopup: false,
      purchaseList: [{
        name: '',
        quantity: '',
        price: ''
      }],
      checkoutUrl: '',
      isSubmitting: false,
      checkoutUrlCopied: false
    };
  },

  mounted() {
    getPlans().then(response => {
      this.setPlans(response.data.data);
    });

  },

  methods: {
    ...mapActions("subscription", {
      setPlans: "setPlans",
      setPlan: "setPlan",
      togglePlanStatusInState: "togglePlanStatusInState",
      togglePlanAvailabilityInState: "togglePlanAvailabilityInState",
    }),

    addNewProductRow() {
      this.purchaseList.push({ name: '', quantity: '', price: '' });
    },

    deleteProduct(index) {
      this.purchaseList.splice(index, 1);
    },

    isProductValid(product) {
      return product.name.trim() !== '' && product.quantity !== '' && product.price !== '';
    },

    isListValid() {
      return this.purchaseList.every(product => this.isProductValid(product));
    },

    getLink() {
      this.isSubmitting = true;
      this.checkoutUrl = '';

      const isValidPurchase = this.purchaseList.every(product => this.isProductValid(product)) && this.purchaseList.length > 0;
      
      if(isValidPurchase){
        const products =
          this.purchaseList.map(product => ({
            name: product.name,
            quantity: product.quantity,
            price: product.price
          }));
  
        try {
          generate2CheckoutUrl(products).then(res => {
            this.checkoutUrl = res.data;
            this.isSubmitting = false;
          })
        } catch (error) {
          console.error("Error generating signature:", error);
          this.isSubmitting = false;
        }
      } else {
        console.error("Invalid Product(s)")
        this.isSubmitting = false;
      }
    },

    copyCheckoutUrl() {
      navigator.clipboard.writeText(this.checkoutUrl)
      this.checkoutUrlCopied = true;
      setTimeout(() => {
        this.checkoutUrlCopied = false;
      }, 2000);
    },

    togglePlanStatusInLocalState() {
        this.togglePlanStatusInState(this.currentPlanId);
    },

    togglePlanAvailabilityInLocalState() {
        this.togglePlanAvailabilityInState(this.currentPlanId);
    },

    initTogglePlanStatus(planId, status) {
      this.showConfirmationPopup = true;
      this.newPlanStatus = status;
      this.currentPlanId = planId;
    },

    initTogglePlanAvailability(planId, status) {
        this.showAvailabilityConfirmationPopup = true;
        this.newPlanAvailability = status;
        this.currentPlanId = planId;
    },

    togglePlanStatus() {
      togglePlanStatusByPlanId(this.currentPlanId).then(response => {
        this.setPlan(response.data.data);
      }).catch(error => {
        this.togglePlanStatusInLocalState();
        console.log(error);
        this.showErrorPopup = true;
      });
      
      this.showConfirmationPopup = false;
    },

    togglePlanAvailability() {
      togglePlanAvailabilityByPlanId(this.currentPlanId).then(response => {
        this.setPlan(response.data.data);
      }).catch(error => {
        this.togglePlanAvailabilityInLocalState();
        console.log(error);
      });
      
      this.showAvailabilityConfirmationPopup = false;
    }

  },

  computed: {
    ...mapGetters("subscription", {
      plans: "getAllPlans",
    }),
  }
}
</script>

<style scoped>
.top-margin {
  margin-top: 30vh;
}
.quotation {
  font-size: 80px;
}
.plan-header {
  margin-bottom: 15px;
  text-align: left;
}
.plan-content {
    align-items: center;
    border-bottom: 1px solid #d7d7d7;
}
.plan-content div:nth-child(1) {
  text-align: left;
  min-width: 25%;
  margin-right: 10px;
}
.plan-content div:nth-child(2) {
  text-align: left;
  min-width: 40%;
}
.help-info {
    font-size: 13px;
}
.purchase-form {
  margin: 32px 0;
}
.form-actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 24px 0;
  gap: 16px;
}
.link-container {
  display: flex;
  align-items: center;
  gap: 10px;
}
.text-center {
  text-align: center;
}
.product-data {
  text-align: center;
  color: black;
  border: 1px solid black;
}
.copy-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
}
.table-data {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
::placeholder {
  color: blue;
  opacity: 0.5;
}
</style>
