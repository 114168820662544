import LoginPage from "@/views/LoginPage.vue";
import ResetPasswordPage from "@/views/ResetPasswordPage.vue";
import RequestPasswordChange from "@/views/RequestPasswordChange.vue";
import Plans from "@/views/Plans.vue";
import AllPracticesReport from "@/views/AllPracticesReport.vue";
import AllDoctorsList from "@/views/AllDoctorsList.vue";
import AllPracticesList from "@/views/AllPracticesList.vue";
import AddPracticeWithDoctor from "@/views/AddPracticeWithDoctor.vue";
import CreateAdmin from "@/views/CreateAdmin.vue";
import AdminsView from "@/views/AdminsView.vue";
import SinglePractice from "@/views/SinglePractice.vue";
import SingleDoctor from "@/views/SingleDoctor.vue";
import AddPracticeToDoctor from "@/views/AddPracticeToDoctor.vue";

export const routes = [
  {
    path: "/",
    redirect: "/plans",
  },
  {
    path: "/login",
    name: "LoginPage",
    component: LoginPage,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/plans",
    name: "Plans",
    component: Plans,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/practices",
    name: "AllPracticesList",
    component: AllPracticesList,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/account/new",
    name: "AddPracticeWithDoctor",
    component: AddPracticeWithDoctor,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/admin/new",
    name: "CreateAdmin",
    component: CreateAdmin,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/password/forgot",
    name: "RequestPasswordChange",
    component: RequestPasswordChange,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/reports/all",
    name: "AllPracticesReport",
    component: AllPracticesReport,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/doctors",
    name: "AllDoctorsList",
    component: AllDoctorsList,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/doctors/:id",
    name: "SingleDoctor",
    component: SingleDoctor,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/admins",
    name: "Admins",
    component: AdminsView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/practices/:id",
    name: "SinglePractice",
    component: SinglePractice,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/password/reset",
    name: "ResetPasswordPage",
    component: ResetPasswordPage,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/doctor/:doctorId/practice",
    name: "AddPracticeToDoctor",
    component: AddPracticeToDoctor,
    meta: {
      requiresAuth: true,
    },
  },
];
