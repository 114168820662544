<template>
  <div class="main-container">
    <VerticalNavbar v-if="isAuthenticated" />
    <Loader v-if="isLoading" color="#4099de"/>
    <div :class="{ pages: isAuthenticated }">
      <router-view> </router-view>
    </div>
  </div>
</template>

<script>
import VerticalNavbar from "@/layouts/VerticalNavbar.vue";
import { mapGetters } from "vuex";
import Loader from "@/components/Loader.vue"
import { getAvailableLangauges } from "@/api/practices/practicesApi";
import store from "@/store/storeIndex";

export default {
  name: "App",
  components: {
    VerticalNavbar,
    Loader,
  },

  mounted() {
    this.fetchAvailableLanguages();
  },

  methods: {
    fetchAvailableLanguages() {
      getAvailableLangauges().then(response => {
        store.dispatch('practice/setAvailableLanguages', response.data.data);
      })
    }
  },

  computed: {
    ...mapGetters("user", {
      isAuthenticated: "getAuthenticationState",
    }),
    ...mapGetters("loading", {
      isLoading: "getIfIsLoading",
    }),
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@100;200;300;400;500;600;900&display=swap");

:root {
  --primary-color: #4099de;
  --primary-background-color: #ececec;
  --secondary-color: #2c3e50;
  --secondary-background-color: #7e8ea1;
  --third-background-color: #e0e0e0;
  --danger-color: #e47e91;
  --danger-background-color: #dc3545;
  --error-background-color: #ffe9ed;
  --success-color: #4caf50;
  --success-background-color: #4caf50;
  --primary-text-color: #7c858e;
  --secondary-text-color: #3c4b5f;
  --cancel-color: #f0f0f0;
}

* {
  font-family: "Nunito", sans-serif;
  font-weight: 300;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: var(--secondary-color);
}

body {
  margin: 0;
  padding: 0;
}

.pages * {
  color: var(--secondary-text-color);
}

.pages {
  padding: 50px 50px 50px 300px;
}

body {
  background: var(--primary-background-color) !important;
}

@media screen and (max-width: 576px) {
  #app {
    margin-top: 60px;
  }
}
</style>
