<template>
  <v-dialog persistent v-model="popupState">
    <v-card
      width="600px"
      class="d-flex flex-column justify-content-between pa-5"
      rounded
    >
      <div class="confirmation-diaglogue-title">
        <h2>
          <slot name="title"></slot>
        </h2>
        <p>
          <slot name="subtitle"></slot>
        </p>
      </div>
      <div height="100px" class="actions-bar d-flex justify-content-end">
        <div class="w-1 mr-4">
          <CallToActionButton class="grey" @buttonClick="handleCancel">
            {{ cancellationText || "Cancel" }}
          </CallToActionButton>
        </div>
        <div class="w-1">
          <CallToActionButton :disabled="isLoading" @buttonClick="handleSubmit">
            {{ confirmationText || "Submit" }}
          </CallToActionButton>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "ConfirmationDialogue",
  emits: ["success", "canceled", "update:popup"],
  props: {
    cancellationText: {
      type: String,
      required: true,
    },
    confirmationText: {
      type: String,
      required: true,
    },
    popup: {
      type: Boolean,
      required: true,
    },
  },

  methods: {
    handleCancel() {
      this.$emit("canceled");
      this.popupState = false;
    },

    handleSubmit() {
      this.$emit("success");
    },
  },

  computed: {
    popupState: {
      get() {
        return this.popup;
      },

      set(newValue) {
        this.$emit("update:popup", newValue);
      },
    },
    ...mapGetters("loading", {
      isLoading: "getIfIsLoading",
    }),
  },
};
</script>
