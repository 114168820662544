export const mutations = {

  setPlans(state, plans) {
    plans.forEach(plan => {
      plan.is_active = Boolean(plan.is_active);
      plan.is_available = Boolean(plan.is_available);
    });
    state.plans = plans;
  },

  setPlan(state, plan) {
    var stateIndex = state.plans.findIndex(obj => obj.id === plan.id);
    plan.is_active = Boolean(plan.is_active);
    plan.is_available = Boolean(plan.is_available);
    state.plans[stateIndex] = plan;
  },

  togglePlanStatusInState(state, planId) {
    var stateIndex = state.plans.findIndex(obj => obj.id === planId);
    state.plans[stateIndex].is_active = !state.plans[stateIndex].is_active;
  },

  togglePlanAvailabilityInState(state, planId) {
    var stateIndex = state.plans.findIndex(obj => obj.id === planId);
    state.plans[stateIndex].is_available = !state.plans[stateIndex].is_available;
  }

};
