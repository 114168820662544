<template>
  <h1 class="mb-10 mt-10">
    <slot name="title">Add Admin</slot>
  </h1>
  <div class="d-flex flex-column align-items-center w-100">
    <slot name="form"></slot>
  </div>
</template>

<script>
export default {
  name: "FormLayout",
};
</script>

<style scoped>
:slotted(form) {
  width: 100%;
  max-width: 600px;
}
</style>
