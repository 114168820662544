<template>
  <div class="container margin-top d-flex flex-column justify-content-center">
    <DentiflowLogo class="mt-200" />
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "AuthPage",
}
</script>

<style scoped>
:slotted(form) {
  width: 100%;
  max-width: 600px;
}
</style>