<template>
  <v-progress-linear
    class="loader"
    :color="color"
    indeterminate
  ></v-progress-linear>
</template>

<script>
export default {
  name: "Loader",
  props: {
    color: {
      required: true,
      type: String,
    },
  },
};
</script>

<style scoped>
.loader.v-progress-linear {
  position: absolute;
  top: 0;
}
</style>