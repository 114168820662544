import CallToActionButton from "@/components/CallToActionButton";
import ControlledInputField from "@/components/ControlledInputField";
import TableEmptyState from "@/components/TableEmptyState";
import ConfirmationDialogue from "@/components/dialogues/ConfirmationDialogue";
import InformationDialogue from "@/components/dialogues/InformationDialogue";
import DentiflowLogo from "@/assets/DentiflowLogo";
import DataTable from "@/components/DataTable";
import ItemInfo from "@/components/ItemInfo";
import ListLayout from "@/layouts/ListLayout";
import ViewLayout from "@/layouts/ViewLayout";
import FormLayout from "@/layouts/FormLayout";
import AuthPage from "@/layouts/AuthPage";
import ChangePlanDialog from "@/components/subscription/ChangePlanDialog";
import SubscribeDialog from "@/components/subscription/SubscribeDialog";
import PracticeAddons from "@/components/subscription/PracticeAddons";
import PracticeSms from "@/components/subscription/PracticeSms";
import SubscribeToAddonDialog from "@/components/subscription/SubscribeToAddonDialog";
import BuySmsDialog from "@/components/subscription/BuySmsDialog";

export const registerComponents = (app) => {
  app.component("DentiflowLogo", DentiflowLogo);
  app.component("CallToActionButton", CallToActionButton);
  app.component("ControlledInputField", ControlledInputField);
  app.component("DataTable", DataTable);
  app.component("ItemInfo", ItemInfo);
  app.component("ViewLayout", ViewLayout);
  app.component("ListLayout", ListLayout);
  app.component("ConfirmationDialogue", ConfirmationDialogue);
  app.component("InformationDialogue", InformationDialogue);
  app.component("TableEmptyState", TableEmptyState);
  app.component("AuthPage", AuthPage);
  app.component("FormLayout", FormLayout);
  app.component("ChangePlanDialog", ChangePlanDialog);
  app.component("SubscribeDialog", SubscribeDialog);
  app.component("PracticeAddons", PracticeAddons);
  app.component("PracticeSms", PracticeSms);
  app.component("SubscribeToAddonDialog", SubscribeToAddonDialog);
  app.component("BuySmsDialog", BuySmsDialog);
};
