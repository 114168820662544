<template>
  <v-card elevation="5" class="w-50 pa-5">
    <div>
      <v-icon class="mr-4">far fa-circle-question</v-icon>
      {{ message }}
    </div>
  </v-card>
</template>

<script>
export default {
  name: "TableEmptyState",
  props: {
    message: {
      type: String,
      required: true,
    },
  },
};
</script>
