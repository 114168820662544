<template>
  <FormLayout>
    <template #title> Add Practice to Doctor </template>
    <template #form>
      <AddPracticeToDoctorForm />
    </template>
  </FormLayout>
</template>

<script>
import AddPracticeToDoctorForm from "@/components/AddPracticeToDoctorForm.vue";

export default {
  name: "AddPracticeToDoctor",
  components: {
    AddPracticeToDoctorForm,
  },
};
</script>
