<template>
  <FormLayout>
    <template #title> Create Admin </template>
    <template #form>
      <CreateAdminForm />
    </template>
  </FormLayout>
</template>

<script>
import CreateAdminForm from "@/components/CreateAdminForm.vue";

export default {
  name: "CreateAdmin",
  components: {
    CreateAdminForm,
  },
};
</script>
