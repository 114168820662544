<template>
  <ViewLayout v-if="doctorFull">
    <template #item-display>
      <h2 class="text-left mb-5">Doctor: {{ doctor.name }}</h2>
      <ItemInfo :item="doctor" />
    </template>
    <template #lists>
      <ListLayout>
        <template #header>
          <h2>Practices</h2>
          <router-link :to="`/doctor/${doctorFull.id}/practice`">
            <CallToActionButton> Add Practice </CallToActionButton>
          </router-link>
        </template>
        <template #list>
          <DataTable
            v-if="practices.info.length > 0"
            :titles="practices.keys"
            :items="practices.info"
          />
          <TableEmptyState
            message="This Doctor doesn't have any practice!"
            v-else
          />
        </template>
      </ListLayout>
    </template>
  </ViewLayout>
</template>

<script>
import { getDoctorById } from "@/api/doctors/index.js";

export default {
  name: "SingleDoctor",
  data() {
    return {
      doctorFull: null,
      doctor: null,
      practices: {
        keys: [
          { id: "Id" },
          { name: "Name" },
          { email: "Email" },
          { phone_number: "Phone Number" },
          { country: "Country" },
          { total_sms: "Total SMS" },
        ],
        info: [],
      },
    };
  },

  async created() {
    this.doctorFull = await getDoctorById(this.$route.params.id);
    this.doctor = this.setDoctorInfo();
    this.practices.info = this.doctorFull.clinics;
  },

  methods: {
    setDoctorInfo() {
      return {
        id: this.doctorFull.id,
        name: `${this.doctorFull.first_name} ${this.doctorFull.last_name}`,
        email: this.doctorFull.email,
        phone_number: this.doctorFull.phone_number,
        main_clinic: this.doctorFull.clinic?.name,
        preferred_language: this.doctorFull.preferred_language,
        last_active: this.doctorFull.last_active
      };
    },
  },
};
</script>
