import { state } from "./state";
import { getters } from "./getters";
import { actions } from "./actions";
import { mutations } from "./mutations";

const user = {
  state,
  actions,
  getters,
  mutations,
  namespaced: true,
};

export default user;
