<template>
  <slot name="item-display"></slot>
  <div class="lists-wrapper">
    <slot name="lists"></slot>
  </div>
</template>

<script>
export default {
  name: "ViewLayout",
};
</script>

<style scoped>
.lists-wrapper {
  margin-top: 84px;
}
</style>
