<template>
  <ListLayout>
    <template #header>
      <h2 class="data-table">All Practices Report</h2>
      <div class="action-bar d-flex">
        <DateFilter @newReports="checkReports" />
        <CallToActionButton :disabled="!jsonReport" @buttonClick="exportToCsv">
          Export
        </CallToActionButton>
      </div>
    </template>
    <template #list>
      <DataTable
        v-if="reports.length > 0"
        :items="reports"
        :titles="titles"
        :height="reports.length >= 20 ? '80vh' : null"
      />
    </template>
  </ListLayout>
</template>

<script>
import { getAllPracticesReport } from "@/api/reports/reportsApi.js";
import DateFilter from "@/components/DateFilter.vue";
import Papa from "papaparse";

export default {
  name: "AllPracticesView",
  components: {
    DateFilter,
  },
  data() {
    return {
      reports: [],
      titles: [
        { id: "Id" },
        { subscription_name: "Subscription" },
        { subscription_renewal_type: "Renewal Type" },
        { name: "Name" },
        { owner_first_name: "Owner F.Name" },
        { owner_last_name: "Owner L.Name" },
        { country: "Country" },
        { phone_number: "Phone Number" },
        { total_users_count: "Total Users" },
        { doctors_count: "Total Doctors" },
        { assistants_count: "Number Of Assistants" },
        { rooms_count: "Rooms Count" },
        { patients_count: "Patients" },
        { invoices_count: "Invoices" },
        { appointments_count: "Appointments" },
        { created_reminders_count: "Created Reminders" },
        { sent_reminders_count: "Sent Reminders" },
        { sms_quantity: "SMS Quantity"},
        { owner_email: "Owner Email" },
        { owner_phone: "Owner Phone Number" },
        { created_at: "Created At" },
        { last_active : "Last Active"},
        { subscription_expired_at : "Expired At"}
      ],
      jsonReport: null,
    };
  },

  async created() {
    this.reports = await getAllPracticesReport();
    this.jsonReport = JSON.stringify(this.reports);
  },

  methods: {
    exportToCsv() {
      let data = JSON.parse(this.jsonReport);

      const modifiedData = data.map(item => {
        return {
          id: item.id,
          subscription_name: item.subscription_name,
          subscription_renewal_type: item.subscription_renewal_type,
          name: item.name,
          owner_first_name: item.owner_first_name,
          owner_last_name: item.owner_last_name,
          email: item.email,
          country: item.country,
          country_code: item.country_code,
          total_users_count: item.total_users_count,
          doctors_count: item.doctors_count,
          assistants_count: item.assistants_count,
          rooms_count: item.rooms_count,
          patients_count: item.patients_count,
          invoices_count: item.invoices_count,
          appointments_count: item.appointments_count,
          created_reminders_count: item.created_reminders_count,
          sent_reminders: item.sent_reminders,
          sms_quantity: item.sms_quantity,
          sms_from: item.sms_from,
          phone_number: item.phone_number,
          owner_id: item.owner_id,
          owner_email: item.owner_email,
          owner_phone: item.owner_phone,
          created_at: item.created_at,
          last_active: item.last_active,
          expired_at: item.subscription_expired_at
        }
      });

      const blob = new Blob([Papa.unparse(modifiedData)], {
        type: "text/csv;charset=utf-8;",
      });
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "reports.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    },

    checkReports(data) {
      this.reports = data;
      this.jsonReport = JSON.stringify(this.reports);
    },
  },
};
</script>

<style scoped>
.action-bar {
  gap: 50px;
}
</style>
