import { Api } from "./api";

export const login = (credentials) => {
  return Api.post("/login", credentials).then((data) => {
    return data.data;
  });
};

export const getAuthenticatedUser = () => {
  return Api.get("/auth").then((data) => {
    return data.data;
  });
};

export const requestResetPasswordLink = (email) => {
  return Api.post("/forgot-password", email);
};

export const resetPassword = (credentials) => {
  return Api.post("/reset-password", credentials).then((data) => {
    return data;
  });
};

export const signOut = () => {
  return Api.post("/logout").then( (response) => {
    return response.data;
  });
};

export const saveApiToken = (data) => {
  Api.defaults.headers["Authorization"] = `Bearer ${data.token}`;
  localStorage.setItem("apiToken", data.token);
};
