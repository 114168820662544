<template>
  <div class="header d-flex justify-content-between align-items-center mb-4 mt-5">
    <h2>Subscription</h2>
    <div class="d-flex" v-if="subscription">
      <CallToActionButton class="ml-2" :disabled="!allowRenewal" @buttonClick="activateRenewPopup = !activateRenewPopup">
        Renew
      </CallToActionButton>
      <CallToActionButton class="ml-2">
        <ChangePlanDialog 
          v-model:isOpen="showChangePlanDialog"
          :plans="plans"
          :currentPlanId="subscription.plan.id"
          @changePlan="changePlan"
        ></ChangePlanDialog>
        Change Plan
      </CallToActionButton>
    </div>
  </div>
  <ItemInfo :item="subscriptionInfo" v-if="subscription" />
  <div v-if="!subscription">
    <h4><i>This practice is not subscribed to any plan</i></h4>
    <CallToActionButton class="ml-2">
        <SubscribeDialog 
          v-model:isOpen="showSubscribeDialog"
          :plans="plans"
          @subscribe="subscribe"
        ></SubscribeDialog>
        Subscribe Practice
      </CallToActionButton>
  </div>
  <ConfirmationDialogue
    cancellationText="Cancel"
    confirmationText="Renew"
    @success="renewSubscription"
    v-model:popup="activateRenewPopup"
  >
    <template #title>Renew Subscription?</template>
    <template #subtitle>Are you sure you want to renew this practice subscription? the action is irreversable</template>
  </ConfirmationDialogue>
  <InformationDialogue
      :confirmationText="'OK'"
      v-model:popup="activateRenewPopupError"
    >
      <template #title>
        Unable to renew subscription.
      </template>
      <template #subtitle>
        Error while renewing subscription, check if it is already renewed 
      </template>
  </InformationDialogue>
  <InformationDialogue
    :confirmationText="'OK'"
    v-model:popup="activateChangePlanPopupError"
  >
    <template #title>
      Unable to change plan.
    </template>
    <template #subtitle>
      Error while changing plan, probably because practice consumption exceeds the limits of the new plan
    </template>
  </InformationDialogue>
  <InformationDialogue
      :confirmationText="'OK'"
      v-model:popup="activateSubscribePopupError"
    >
      <template #title>
        Unable to Subscribe practice.
      </template>
      <template #subtitle>
        Error while subscribing practice, check if the practice have an active subscription
      </template>
  </InformationDialogue>

</template>

<script>

import { getPlansMixin } from '@/mixins/getPlansMixin.js';
import { changePracticePlan, renewSubscription, subscribePractice } from '@/api/subscriptions/subscriptionsApi';

export default {
  name: "PracticeSusbscriptionDsiplay",
  mixins: [getPlansMixin],
  emits: ['subscriptionChanged'],
  props: {
    subscription: {
      required: true,
    },
    practiceId: {
      required: true,
    }
  },

  data() {
    return {
      activateRenewPopup: false,
      activateRenewPopupError: false,
      activateChangePlanPopupError: false,
      activateSubscribePopupError: false,
      showChangePlanDialog: false,
      showSubscribeDialog: false,
      plans: null,
      allowRenewal: false,
    }
  },

  created() {
    this.allowRenewal = this.checkCanRenew();
  },

  updated() {
    this.allowRenewal = this.checkCanRenew();
  },

  methods: {
    changePlan(planId, startDate) {
      changePracticePlan(this.practiceId, planId, startDate).then(response => {
        this.$emit('subscriptionChanged', response.data.data);
      }).catch(() => {
        this.activateChangePlanPopupError = true;
      })
    },

    subscribe(planId, startDate) {
      subscribePractice(this.practiceId, planId, startDate).then(response => {
        this.$emit('subscriptionChanged', response.data.data);
      }).catch(() => {
        this.activateSubscribePopupError = true;
      })
    },

    checkCanRenew() {
      if(this.subscription) {   
        let now = new Date();
        let expiry = new Date(this.subscription.expired_at);
        expiry.setDate(expiry.getDate()-14);
        return now.getTime() >= expiry.getTime();
      } else {
        return false;
      }
    },
    renewSubscription() {
      renewSubscription(this.practiceId, this.subscription.id).then(response => {
        this.$emit('subscriptionChanged', response.data.data);
        this.activateRenewPopup = false;
      }).catch(() => {
        this.activateRenewPopup = false;
        this.activateRenewPopupError = true;
      });
    }
  },

  computed: {
    subscriptionInfo: function() {
      if(this.subscription) {
        return {
          plan: this.subscription.plan.name,
          "Renew Every": this.subscription.plan.renewal_value + ' ' + this.subscription.plan.renewal_type,
          "Start Date": this.subscription.started_at,
          "Expire At": this.subscription.expired_at,
          "Free Trial": this.subscription.free_trial_expired_at ? this.subscription.free_trial_expired_at : false
        };
      } else {
        return null
      }
    }
  },
  
}
</script>