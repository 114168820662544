import { Api } from "@/api/api.js";

export const getAllPracticesReport = (from, to) => {
  return Api.get("/reports/practices", {
    params: {
      from: from || null,
      to: to || null,
    },
  }).then((response) => {
    return response.data.data;
  });
};
