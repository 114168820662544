<template>
  <v-dialog
    persistent
    class="upload-dialog"
    activator="parent"
    v-model="popupState"
  >
    <v-card class="pa-5">
      <div>
        <h2>Upload Patients List</h2>
        <v-card-text>
          Please make sure that the required columns are contained in the sheet
          you are uploading, and no extra columns are added. Extra columns may
          cause this operation to fail!
          <br />
          <a :href="getTemplateUrl" download="pattients_template.csv"
            >Download Template</a
          >
        </v-card-text>
      </div>
      <div class="d-flex justify-content-between align-items-center">
        <div class="pa-4 d-flex flex-column align-items-start">
          <CallToActionButton>
            <label class="custom-button" for="csv-upload">
              <input
                id="csv-upload"
                class="upload-field"
                type="file"
                accept=".csv"
                @input="readCsvFile"
              />
              Upload CSV
            </label>
          </CallToActionButton>
          <small v-if="!uploadError" class="ml-1 success">{{
            importedCsvName
          }}</small>
          <small v-else class="ml-1 error">{{ uploadError }}</small>
        </div>
        <div class="d-flex">
          <CallToActionButton class="mr-3 grey" @buttonClick="handleCancel">
            Cancel
          </CallToActionButton>
          <CallToActionButton
            :disabled="importedPatients || !isLoading ? false : true"
            @buttonClick="handleImportPatients"
          >
            Submit
          </CallToActionButton>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import Papa from "papaparse";
import { importPatients } from "@/api/practices/practicesApi.js";
import { mapGetters } from "vuex";

export default {
  name: "PatientsImport",
  emits: ["success", "canceled", "update:isOpen", "error"],
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    practiceId: {
      required: true,
    },
  },

  data() {
    return {
      importedCsvName: "",
      importedPatients: null,
      patientsRequiredFields: [
        {
          first_name: "",
          last_name: "",
          file_code: "",
          gender: "",
          maritial_status: "",
          email: "",
          middle_name: "",
          maiden_name: "",
          phone_number: "",
          alt_number: "",
          occupation: "",
          address: "",
          dob: "",
          profession: "",
          medical_notes: "",
          weight: "",
          height: "",
          salutation: "",
          created_at: ""
        },
      ],
      uploadError: null,
    };
  },

  methods: {
    readCsvFile(event) {
      const fileType = event.target.files[0].type;
      if (fileType !== "text/csv" && fileType !== "application/vnd.ms-excel") {
        this.uploadError = "This is not a CSV file";
        return;
      }
      this.uploadError = null;
      this.importedCsvName = event.target.files[0].name;

      const reader = new FileReader();
      reader.readAsBinaryString(event.target.files[0]);
      reader.onload = () => {
        let result = [];
        const csv = reader.result;
        const lines = csv.split("\r" + "\n");
        const headers = lines[0].split(",");

        for (let i = 0; i < lines.length; i++) {
          if (!lines[i]) continue;
          const patient = {};
          let currentLine = lines[i];
          currentLine = currentLine.split(",");

          for (let j = 0; j < headers.length; j++) {
            const key = headers[j].trim();
            const value = currentLine[j].trim();
            patient[key] = value;
          }
          result.push(patient);
        }
        result.shift();
        this.importedPatients = result;
      };
    },

    handleCancel() {
      this.popupState = false;
    },

    async handleImportPatients() {
      try {
        await importPatients(this.practiceId, this.importedPatients);
        this.$emit("success");
      } catch (error) {
        this.$emit("error");
        console.log(error);
      }
      this.popupState = false;
    },
  },

  computed: {
    popupState: {
      get() {
        return this.isOpen;
      },
      set(newValue) {
        this.$emit("update:isOpen", newValue);
      },
    },
    getTemplateUrl() {
      const template = JSON.stringify(this.patientsRequiredFields);
      const blob = new Blob([Papa.unparse(template)], {
        type: "text/csv;charset=utf-8;",
      });
      return URL.createObjectURL(blob);
    },
    ...mapGetters("loading", {
      isLoading: "getIfIsLoading",
    }),
  },
};
</script>

<style scoped>
.upload-dialog {
  min-height: 300px !important;
  min-width: 700px !important;
}
.upload-field {
  opacity: 0;
  position: absolute;
  z-index: -1;
}
.custom-button {
  width: 100%;
  cursor: pointer;
}
.error {
  color: var(--danger-color);
}
.success {
  color: var(--success-color);
}
</style>
