<template>
  <form class="d-flex flex-column align-items-center" action="">
    <ControlledInputField
      inputFor="practice-name"
      :errors="errors.practiceName"
    >
      <input
        type="text"
        name="practice-name"
        placeholder="Practice Name"
        v-model="formData.practiceName"
      />
    </ControlledInputField>

    <ControlledInputField inputFor="country" :errors="errors.country">
      <input
        type="text"
        name="country"
        placeholder="Country"
        v-model="formData.country"
      />
    </ControlledInputField>

    <ControlledInputField inputFor="country-code" :errors="errors.countryCode">
      <input
        type="text"
        name="country-code"
        placeholder="Country Code"
        v-model="formData.countryCode"
      />
    </ControlledInputField>
    <CallToActionButton class="mt-4" @buttonClick="handleSubmit">
      Add Practice
    </CallToActionButton>
  </form>
</template>

<script>
import { addPracticeToDoctor } from "@/api/practices/practicesApi.js";
import { Validator } from "@/validation/validator.js";

export default {
  name: "AddPracticeForm",
  data() {
    return {
      formData: {
        practiceName: "",
        country: "",
        countryCode: "",
      },
      errors: {
        practiceName: [],
        country: [],
        countryCode: [],
      },
    };
  },

  methods: {
    async handleSubmit(event) {
      event.preventDefault();
      const { success, errors } = Validator.validate(this.formData, {
        practiceName: "required",
        country: "required",
        countryCode: "required|size:2",
      });
      if (success) {
        this.errors.practiceName = [];
        this.errors.country = [];
        this.errors.countryCode = [];
        try {
          const doctor = await addPracticeToDoctor(
            this.$route.params.doctorId,
            {
              name: this.formData.practiceName,
              country: this.formData.country,
              country_code: this.formData.countryCode.toLowerCase(),
            }
          );
          this.$router.push(`/doctors/${doctor.id}`);
        } catch (error) {
          console.log(error);
        }
      } else {
        this.errors = errors;
      }
    },
  },
};
</script>
