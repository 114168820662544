<template>
  <AuthPage>
    <ForgotPasswordForm />
  </AuthPage>
</template>

<script>
import ForgotPasswordForm from "@/components/ForgotPasswordForm.vue";

export default {
  name: "RequestChangePassword",
  components: {
    ForgotPasswordForm,
  },
};
</script>
