<template>
  <form class="date-inputs d-flex">
    <div class="d-flex flex-column align-items-center">
      <ControlledInputField inputName="From" inputFor="from">
        <input
          type="datetime-local"
          id="from"
          v-model="fromDate"
          @change="isDisabledFilterButton = false"
        />
      </ControlledInputField>
    </div>
    <div class="d-flex flex-column justify-content-center align-items-center">
      <ControlledInputField inputName="To" inputFor="to" :errors="toDateErrors">
        <input
          type="datetime-local"
          id="to"
          v-model="toDate"
          @change="isDisabledFilterButton = false"
        />
      </ControlledInputField>
    </div>
    <CallToActionButton
      class="filter-button"
      :disabled="isDisabledFilterButton || (!this.fromDate && !this.toDate)"
      @buttonClick="getReportsWithDates"
    >
      Apply
    </CallToActionButton>
  </form>
</template>

<script>
import { getAllPracticesReport } from "@/api/reports/reportsApi.js";

export default {
  name: "DateFilter",
  emits: ["newReports"],

  data() {
    return {
      fromDate: null,
      toDateErrors: [],
      toDate: null,
      isDisabledFilterButton: true,
    };
  },

  methods: {
    async getReportsWithDates(event) {
      event.preventDefault();
      if (this.fromDate && this.toDate) {
        const from = new Date(this.fromDate);
        const to = new Date(this.toDate);
        
        if (from > to) {
          this.toDateErrors = ["To can't be earlier than From"];
          return;
        }
        this.toDateErrors = [];
      }
      this.isDisabledFilterButton = true;

      const reports = await getAllPracticesReport(this.fromDate, this.toDate);
      this.$emit("newReports", reports);
    },
  },
};
</script>

<style scoped>
input[type="date"] {
  cursor: pointer;
}

.date-inputs {
  gap: 10px;
}
</style>
