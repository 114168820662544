import axios from "axios";
import router from "@/router/routerIndex.js";
import store from "../store/storeIndex";

export const Api = axios.create({
  baseURL: process.env.VUE_APP_BACK_END_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

Api.interceptors.request.use(
  (config) => {
    store.dispatch("loading/activateLoader");
    const apiToken = localStorage.getItem("apiToken");
    if (apiToken) {
      config.headers.Authorization = `Bearer ${apiToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

Api.interceptors.response.use(
  (data) => {
    store.dispatch("loading/deactivateLoader");
    return data;
  },
  (error) => {
    if (error.response.status === 401) {
      store.dispatch("user/changeAuthState", false);
      localStorage.clear();
      router.push("/login");
    }
    store.dispatch("loading/deactivateLoader");
    return Promise.reject(error);
  }
);
