<template>
  <v-dialog
    persistent
    class="upload-dialog"
    activator="parent"
    v-model="popupState"
  >
    <v-card class="pa-5 card-container">
      <div>
        <h2>Subscribe Practice</h2>
        <v-card-text>
          <div>
            Subscribing a practice will grant him access to dentiflow system! please do this after you are paid!!!
          </div>
          <div style="margin-top:15px">
            <h4>Choose plan</h4>
            <div class="d-flex plans-container">
              <div 
                v-for="plan in plans" 
                :key="plan.id" 
                @click="selectPlan(plan.id)"
                class="plan" 
                :class="{'selected': plan.id === selectedPlanId}"
              >
                {{plan.name}}<br>renew every {{plan.renewal_value}} {{plan.renewal_type}}
              </div>
            </div>
          </div>
          <div style="margin-top: 20px">
            Start Date
            <ControlledInputField inputName="StartDate" inputFor="startDate">
              <input
                type="date"
                id="startDate"
                v-model="startDate"
              />
            </ControlledInputField>
          </div>
        </v-card-text>
      </div>
      <div class="d-flex justify-content-end align-items-center">
        <div class="d-flex">
          <CallToActionButton class="mr-3 grey" @buttonClick="handleCancel">
            Cancel
          </CallToActionButton>
          <CallToActionButton
            @buttonClick="handleChangePlan"
          >
            Proceed
          </CallToActionButton>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
export default {

  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    currentPlanId: {
      type: Number,
      required: false
    }
  },

  data() {
    return {
      selectedPlanId: this.currentPlanId,
      startDate: null,
    }
  },

  methods: {
    handleCancel() {
      this.popupState = false;
    },
    handleChangePlan() {
      this.$emit("subscribe", this.selectedPlanId, this.startDate);
      this.popupState = false;
    },
    selectPlan(planId) {
      this.selectedPlanId = planId;
    }
  },

  computed: {
    popupState: {
      get() {
        return this.isOpen;
      },
      set(newValue) {
        this.$emit("update:isOpen", newValue);
      },
    },
    ...mapGetters("subscription", {
      plans: "getAllPlans",
    })
  }
  
}
</script>
<style scoped>
  .plans-container{
    flex-wrap: wrap;
  }
  .plan {
    user-select: none;
    border: 1px solid grey;
    padding: 10px;
    max-width: 165px;
    cursor: pointer;
    margin: 10px 10px 0px 0px;
  }
  .selected {
    border: 2px solid var(--primary-color);
  }
  .card-container {
    max-height: 500px;
    overflow: auto !important;
  }
</style>