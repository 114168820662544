<template>
  <form
    action=""
    class="request-password-change-form d-flex w-100 container align-items-center flex-column"
  >
    <ControlledInputField
      inputName="Email"
      inputFor="email"
      :errors="errors.email"
    >
      <input
        type="email"
        name="email"
        v-model="formData.email"
        placeholder="Email"
      />
    </ControlledInputField>

    <CallToActionButton
      :disabled="isLoading"
      class="auth-button"
      @buttonClick="handleSubmit"
      >Send Link</CallToActionButton
    >
  </form>
</template>

<script>
import { requestResetPasswordLink } from "@/api/auth.js";
import { Validator } from "@/validation/validator.js";
import { mapGetters } from "vuex";

export default {
  name: "ForgotPasswordForm",
  data() {
    return {
      formData: {
        email: "",
      },
      errors: {
        email: null,
      },
    };
  },

  methods: {
    async handleSubmit(event) {
      event.preventDefault();
      const { success, errors } = Validator.validate(this.formData, {
        email: "required|email",
      });

      this.errors = errors;

      if (success) {
        try {
          await requestResetPasswordLink(this.formData);
          this.$router.push("/login");
        } catch (e) {
          console.log(e);
          this.errors.email =
            "There is no account with the email you have provided!";
        }
      }
    },
  },

  computed: {
    ...mapGetters("loading", {
      isLoading: "getIfIsLoading",
    }),
  },
};
</script>

<style scoped>
.request-password-change-form {
  max-width: 500px;
}
</style>
