<template>
  <v-card elevation="5 w-100">
    <v-table fixed-header :height="height">
      <template v-slot:default>
        <thead>
          <tr>
            <th
              v-for="(header, index) in headers"
              :key="index"
              class="title text-left"
            >
              {{ header }}
            </th>
            <th v-if="view" style="z-index: 2"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in items" :key="item.id">
            <td v-for="(key, index) in keys" :key="index" class="text-left">
              {{ item[key] || "-" }}
            </td>
            <td v-if="view">
              <router-link :to="`${this.$route.path}/${item?.id}`">
                <v-icon class="view-icon" size="x-small">far fa-eye</v-icon>
              </router-link>
            </td>
          </tr>
        </tbody>
      </template>
    </v-table>
  </v-card>
</template>

<script>
export default {
  name: "DataTable",
  props: {
    items: {
      required: true,
    },
    titles: {
      required: true,
    },
    view: {
      type: Boolean,
    },
    height: {
      required: false,
      type: String,
    },
  },

  data() {
    return {
      headers: this.getHeaders(),
      keys: this.getKeys(),
    };
  },

  methods: {
    getHeaders() {
      const headers = [];
      for (const title of this.titles) {
        headers.push(title[Object.keys(title)]);
      }
      return headers;
    },
    getKeys() {
      const tableKeys = [];
      for (const key of this.titles) {
        tableKeys.push(Object.keys(key)[0]);
      }
      return tableKeys;
    },
  },
};
</script>

<style scoped>
.title {
  font-size: 14px !important;
  font-weight: 600;
  color: var(--primary-text-color);
}
</style>
