<template>
  <ListLayout>
    <template #header>
      <h2 class="data-table">All Doctors List</h2>
    </template>
    <template #list>
      <DataTable
        class="data-table"
        v-if="doctors.length > 0"
        :titles="titles"
        :items="doctors"
        :view="true"
        :height="doctors.length >= 20 ? '80vh' : null"
      />
    </template>
  </ListLayout>
</template>

<script>
import { getAllDoctors } from "@/api/doctors/index.js";

export default {
  name: "AllDoctorsList",

  data() {
    return {
      doctors: [],
      titles: [
        { id: "Id" },
        { first_name: "First Name" },
        { last_name: "Last Name" },
        { email: "Email" },
        { phone_number: "Phone Number" },
        { preferred_language: "Prefferred Language" },
        { main_clinic: "Main Practice Id" },
        {last_active: "Last Active"}
      ],
    };
  },

  async created() {
    this.doctors = await getAllDoctors();
  },
};
</script>
<style scoped>
.data-table {
  margin-bottom: 0;
}
</style>
