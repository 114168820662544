import { createRouter, createWebHistory } from "vue-router";
import { routes } from "./routes";
import store from "../store/storeIndex";

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth) {
    if (!store.state.user.isAuthenticated) {
      router.push("/login");
    } else {
      const userInfo = store.getters["user/getUserInfo"];
      if (!userInfo.name) {
        store.dispatch("user/setUserInfo");
      }
      next();
    }
  } else if (store.state.user.isAuthenticated) {
    next({ path: "/dashboard" });
  } else {
    next();
  }
});

export default router;
