<template>
  <v-card elevation="5">
    <v-table class="text-left" v-if="addons && addons.length > 0">
      <template #default>
        <thead>
          <tr>
            <th class="text-left">Name</th>
            <th class="text-left">Quantity</th>
            <th class="text-left">Started On</th>
            <th class="text-left">Bought On</th>
            <th class="text-left">Next Renewal</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="addon in addons" :key="addon.id">
            <td class="text-left">{{addon.plan.name}}</td>
            <td class="text-left">{{addon.quantity}}</td>
            <td class="text-left">{{formatDate(addon.started_at)}}</td>
            <td class="text-left">{{formatDate(addon.created_at)}}</td>
            <td class="text-left">{{formatDate(addon.renewal_date)}}</td>
          </tr>
        </tbody>
      </template>
    </v-table>
    <v-card-text>
      <h4 v-if="addons && addons.length == 0"><i>Practice do not have any addons!!</i></h4>
      <CallToActionButton class="ml-2">
        <SubscribeToAddonDialog 
          v-model:isOpen="showSubscribeToAddonDialog"
          :availableAddons="availableAddons"
          @subscribe="subscribe"
        ></SubscribeToAddonDialog>
        Add Addons
      </CallToActionButton>
    </v-card-text>
  </v-card>
</template>

<script>


import { getPracticeAddons, getAvailablePracticeAddons, subscribeToAddOn } from "@/api/subscriptions/subscriptionsApi.js";

export default {
  props: {
    practiceId: {
      required: true,
    }
  },

  data() {
    return {
      addons: null,
      availableAddons: null,
      showSubscribeToAddonDialog: false,
      showAdditionErrorPopup: false,
    }
  },

  created() {
    this.getAddons();

    getAvailablePracticeAddons(this.practiceId).then(response => {
      this.availableAddons = response.data.data;
    });

  },

  methods: {
    getAddons() {
      getPracticeAddons(this.practiceId).then(response => {
        this.addons = response.data.data;
      });
    },
    subscribe(addonId, startDate) {
      subscribeToAddOn(this.practiceId, addonId, startDate).then(() => {
        this.getAddons();
      }).catch(() => {
        this.showAdditionErrorPopup = true;
      });
    },
    formatDate(dateString) {
      var options = { year: 'numeric', month: 'short', day: 'numeric' };
      return new Date(dateString).toLocaleDateString('en-us', options);
    }
  }
}
</script>