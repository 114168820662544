<template>
  <div class="container d-flex justify-content-center">
    <form
      action=""
      class="login-form d-flex w-100 container align-items-center flex-column"
    >
      <ControlledInputField
        inputName="Email"
        inputFor="email"
        :errors="errors.email"
      >
        <input
          type="email"
          name="email"
          v-model="formData.email"
          placeholder="Email"
        />
      </ControlledInputField>

      <ControlledInputField
        inputName="Password"
        inputFor="password"
        :errors="errors.password"
      >
        <input
          autocomplete="false"
          type="password"
          name="password"
          v-model="formData.password"
          placeholder="Password"
        />
      </ControlledInputField>

      <router-link to="/password/forgot" class="forgot-password">
        <small class="link-primary"><i>Forgot Password?</i></small>
      </router-link>
      <CallToActionButton
        :disabled="isLoading"
        class="auth-button"
        @buttonClick="handleSubmit"
      >
        Login
      </CallToActionButton>
    </form>
  </div>
</template>

<script>
import { login, saveApiToken } from "../api/auth";
import { Validator } from "@/validation/validator.js";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "LoginForm",

  data() {
    return {
      formData: {
        email: "",
        password: "",
      },
      errors: {
        email: null,
        password: null,
      },
    };
  },

  methods: {
    ...mapActions("user", {
      changeAuthState: "changeAuthState",
      setUserInfo: "setUserInfo",
    }),

    async handleSubmit(event) {
      event.preventDefault();

      const { success, errors } = Validator.validate(this.formData, {
        email: "required|email",
        password: "required|min:8",
      });

      this.errors = errors;

      if (success) {
        try {
          const loginStatus = await login(this.formData);
          this.errors.password = "";
          saveApiToken(loginStatus);
          this.changeAuthState(true);
          this.$router.push("/plans");
        } catch (error) {
          console.log(error);
          this.errors.password = ["Wrong Credentials"];
          this.$router.push("/login");
        }
      }
    },
  },

  computed: {
    ...mapGetters("loading", {
      isLoading: "getIfIsLoading",
    }),
  },
};
</script>

<style scoped>
.logo-container {
  margin-bottom: 30px;
}

.login-form {
  max-width: 500px;
}

.forgot-password {
  width: 100%;
  margin-top: -5px;
  margin-right: 15px;
  text-align: end;
  text-decoration: none;
  color: var(--primary-color);
}
</style>
