<template>
  <ListLayout>
    <template #header>
      <h2 class="mb-0">All Practices List</h2>
      <router-link class="nav-button" to="/account/new">
        <CallToActionButton> Add Practice</CallToActionButton>
      </router-link>
    </template>
    <template #list>
      <v-card class="w-100" elevation="5">
        <v-table
          class="mb-0 text-left no-scrollbar"
          fixed-header
          :height="practices.length >= 20 ? '80vh' : null"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th>Id</th>
                <th>Practice Owner</th>
                <th>Practice Name</th>
                <th>Email</th>
                <th>Phone Number</th>
                <th>Country</th>
                <th>Country Code</th>
                <th>Last Active</th>
                <th style="z-index: 2">Is Active</th>
                <th style="z-index: 2"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="practice in practices" :key="practice?.id">
                <td>
                  <router-link
                    class="nav-button"
                    :to="`/practices/${practice.id}`"
                  >
                    {{ practice.id || "-" }}
                  </router-link>
                </td>
                <td>{{ practice.owner.first_name || "-" }}</td>
                <td>{{ practice.name || "-" }}</td>
                <td>{{ practice.email || "-" }}</td>
                <td>{{ practice.phone_number || "-" }}</td>
                <td>{{ practice.country || "-" }}</td>
                <td>{{ practice.country_code || "-" }}</td>
                <td>{{ practice.last_active || "-" }}</td>
                <td>
                  <v-icon color="green" v-if="practice.is_active">fas fa-check</v-icon>
                  <v-icon color="red" v-else>fas fa-xmark</v-icon>
                </td>
                <td>
                  <router-link :to="`/practices/${practice.id}`">
                    <v-icon class="view-icon" size="x-small">far fa-eye</v-icon>
                  </router-link>
                </td>
              </tr>
            </tbody>
          </template>
        </v-table>
      </v-card>
    </template>
  </ListLayout>
</template>

<script>
import { getAllPractices } from "@/api/practices/practicesApi";

export default {
  name: "AllPracticesList",

  data() {
    return {
      practices: [],
    };
  },

  async created() {
    this.practices = await getAllPractices();
  },
};
</script>

<style scoped>
.page-title {
  text-align: left;
}
.title-wrapper {
  justify-content: space-between;
  align-items: center;
}
.nav-button {
  text-decoration: none;
  margin-bottom: 5px;
}
.custom-button {
  margin-top: 0 !important;
}
</style>
