<template>
  <v-dialog
    persistent
    class="upload-dialog"
    activator="parent"
    v-model="popupState"
  >
    <v-card class="pa-5 card-container">
      <div>
        <h2>Add SMS to practice</h2>
        <v-card-text>
          <div>
            This action is irreversable! please make sure you are paid before you add Tickets!
          </div>
          <div style="margin-top:15px">
            <h4>Choose SMS Package</h4>
            <div class="d-flex plans-container">
              <div 
                v-for="smsPackage in availableSms" 
                :key="smsPackage.id" 
                class="d-flex"
              >
                <div 
                  v-for="variation in smsPackage.variations"
                  :key="variation.id"
                  :class="{'selected': variation.id === selectedVariationId}"
                  class="plan" 
                  @click="selectSmsPackage(smsPackage.id, variation.id)"
                >
                  <div>{{variation.display}}</div>
                  <div>Expire after {{smsPackage.ticket_expiry_value}} {{smsPackage.ticket_expiry_type}}</div>
                </div>
              </div>
            </div>
          </div>
          <div style="margin-top:15px">
            Custom Amount
            <ControlledInputField inputName="customAmount" inputFor="customAmount">
              <input
                type="number"
                id="customAmount"
                v-model="customAmount"
              />
            </ControlledInputField>
          </div>
          <div style="margin-top: 20px">
            Expire On
            <ControlledInputField inputName="Expire At" inputFor="ExpiredAt">
              <input
                type="date"
                id="expiredAt"
                v-model="expiredAt"
              />
            </ControlledInputField>
          </div>
        </v-card-text>
      </div>
      <div class="d-flex justify-content-end align-items-center">
        <div class="d-flex">
          <CallToActionButton class="mr-3 grey" @buttonClick="handleCancel">
            Cancel
          </CallToActionButton>
          <CallToActionButton
            @buttonClick="handleSubscribe"
          >
            Proceed
          </CallToActionButton>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {

  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    availableSms: {
      required: true,
    }
  },

  data() {
    return {
      selectedSmsPackageId: null,
      selectedVariationId: null,
      customAmount: null,
      expiredAt: null,
    }
  },

  methods: {
    handleCancel() {
      this.popupState = false;
    },
    handleSubscribe() {
      var packageId = this.selectedSmsPackageId ? this.selectedSmsPackageId : this.availableSms[0].id;
      this.$emit("subscribe", packageId, this.selectedVariationId, this.expiredAt, this.customAmount);
      this.popupState = false;
    },
    selectSmsPackage(smsPakcageId, variationId) {
      this.selectedSmsPackageId = smsPakcageId;
      this.selectedVariationId = variationId;
    }
  },

  computed: {
    popupState: {
      get() {
        return this.isOpen;
      },
      set(newValue) {
        this.$emit("update:isOpen", newValue);
      },
    },
  }
  
}
</script>
<style scoped>
  .plans-container{
    flex-wrap: wrap;
  }
  .plan {
    user-select: none;
    border: 1px solid grey;
    padding: 10px;
    max-width: 165px;
    cursor: pointer;
    margin: 10px 10px 0px 0px;
  }
  .selected {
    border: 2px solid var(--primary-color);
  }
  .card-container {
    max-height: 500px;
    overflow: auto !important;
  }
</style>