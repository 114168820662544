<template>
  <form
    action=""
    class="reset-password-form d-flex w-100 container align-items-center flex-column"
  >
    <ControlledInputField
      inputName="New Password"
      inputFor="password"
      :errors="errors.password"
    >
      <input
        autocomplete="false"
        type="password"
        name="password"
        v-model="formData.password"
        placeholder="Password"
      />
    </ControlledInputField>

    <ControlledInputField
      inputName="Confirm New Password"
      inputFor="password-confirmation"
      :errors="errors.passwordConfirmation"
    >
      <input
        autocomplete="false"
        type="password"
        name="password-confirmation"
        v-model="formData.passwordConfirmation"
        placeholder="Password Confirmation"
      />
    </ControlledInputField>

    <CallToActionButton
      :disabled="isLoading"
      class="auth-button"
      @buttonClick="handleSubmit"
    >
      Reset Password
    </CallToActionButton>
  </form>
</template>

<script>
import { resetPassword } from "@/api/auth.js";
import { Validator } from "@/validation/validator.js";
import { mapGetters } from "vuex";

export default {
  name: "ResetPassword",

  data() {
    return {
      formData: {
        email: this.$route.query.email,
        password: "",
        passwordConfirmation: "",
        token: this.$route.query.token,
      },
      errors: {
        email: null,
        password: null,
        passwordConfirmation: null,
        token: null,
      },
    };
  },

  methods: {
    async handleSubmit(event) {
      event.preventDefault();

      const isFormValid = Validator.validate(this.formData, {
        email: "required|email",
        password: "min:8|required",
        passwordConfirmation: `required|min:8|matches:password`,
        token: "required",
      });

      this.errors = isFormValid.errors;

      if (isFormValid.success) {
        try {
          await resetPassword({
            email: this.formData.email,
            password: this.formData.password,
            password_confirmation: this.formData.passwordConfirmation,
            token: this.formData.token,
          });
          this.$router.push("/login");
        } catch (e) {
          console.log(e);
          this.errors.passwordConfirmation = [
            "Something went Wrong, Please try again!",
          ];
        }
      }
    },
  },

  computed: {
    ...mapGetters("loading", {
      isLoading: "getIfIsLoading",
    }),
  },
};
</script>

<style scoped>
.reset-password-form {
  max-width: 500px;
}
</style>
