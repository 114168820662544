<template>
  <div class="list-wrapper">
    <div class="header d-flex justify-content-between align-items-center">
      <slot name="header"></slot>
    </div>
    <div class="d-flex justify-content-center">
      <slot name="list"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "ListLayout",
};
</script>

<style scoped>
.header {
  margin-bottom: 30px;
}
</style>
