<template>
  <FormLayout>
    <template #title> Create Account </template>
    <template #form>
      <CreateAccountForm />
    </template>
  </FormLayout>
</template>

<script>
import { mapGetters } from "vuex";
import CreateAccountForm from "@/components/CreateAccountForm.vue";

export default {
  name: "CreateAdmin",
  components: {
    CreateAccountForm,
  },

  computed: {
    ...mapGetters("loading", {
      isLoading: "getIfIsLoading",
    }),
  },
};
</script>
