<template>
  <div class="d-flex flex-column w-100">
    <label :for="inputFor">
      <div :class="{ error: errors && errors[0] }">
        <slot></slot>
      </div>
    </label>
    <small class="error-message">
      <div class="red text-left" v-for="(error, index) in errors" :key="index">
        {{ error }}
      </div>
    </small>
  </div>
</template>

<script>
export default {
  name: "ControlledInputField",
  props: {
    inputFor: {
      required: true,
      type: String,
    },

    errors: {
      required: false,
      type: Array,
    },
  },
};
</script>

<style scoped>
label {
  position: relative;
  height: 48px;
  width: 100%;
}

:slotted(input:focus) {
  outline: none;
  border: 2px solid var(--primary-color);
  background-color: #e8f0fe !important;
}

:slotted(input) {
  width: 100%;
  min-width: 150px;
  height: 100%;
  border: 1px solid #dfe6e9;
  background-color: #f3f4f5 !important;
  border-radius: 10px;
  padding: 11px;
}

label span {
  position: absolute;
  top: 0;
  left: 16px;
  transform: translateY(2px);
  font-size: 12px;
  color: #848188;
  transition-duration: 300ms;
}

.error-message {
  color: #b85a6b !important;
  margin-left: 15px;
  min-height: 21px;
  width: 100%;
}

.red {
  color: var(--danger-color);
}

:slotted(.error input) {
  background: var(--error-background-color) !important;
  border: 2px solid #e47e91 !important;
}
</style>
