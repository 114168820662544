<template>
  <v-card class="info-list" elevation="5">
    <v-table class="text-left">
      <template #default>
        <tbody>
          <tr v-for="(value, index) in item" :key="index">
            <th class="w-25 label">{{ index }}</th>
            <td>{{ value || "-" }}</td>
          </tr>
        </tbody>
      </template>
    </v-table>
  </v-card>
</template>

<script>
export default {
  name: "ItemInfo",
  props: {
    item: {
      required: true,
    },
  },
};
</script>

<style scoped>
.label {
  font-weight: 500;
  font-size: 14px !important;
  color: var(--primary-text-color);
}
</style>
