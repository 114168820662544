import { Api } from "../api";

export const getPlans = () => {
  return Api.get("/plans");
};

export const togglePlanStatusByPlanId = (planId) => {
  return Api.put("/plans/" + planId + "/toggle-status");
}

export const togglePlanAvailabilityByPlanId = (planId) => {
  return Api.put("/plans/" + planId + "/toggle-availability");
}

export const subscribePractice = (practiceId, planId, startDate) => {
  return Api.post(`/practices/${practiceId}/subscribe/${planId}`, {start_date: startDate});
}

export const changePracticePlan = (practiceId, newPlanId, startDate) => {
  return Api.put(`/practices/${practiceId}/plans/${newPlanId}`, {start_date: startDate});
}

export const renewSubscription = (practiceId, subscriptionId) => {
  return Api.post(`/practices/${practiceId}/subscription/renewal`, {subscription_id: subscriptionId});
}

export const getPracticeTickets = (practiceId) => {
  return Api.get(`/practices/${practiceId}/tickets`);
}

export const getPracticeAddons = (practiceId) => {
  return Api.get(`/practices/${practiceId}/addons`);
}

export const getAvailablePracticeTickets = (practiceId) => {
  return Api.get(`/practices/${practiceId}/tickets/available`);
}

export const getAvailablePracticeAddons = (practiceId) => {
  return Api.get(`/practices/${practiceId}/addons/available`);
}

export const buyTicket = (practiceId, ticketId, variationId, expiredAt, customAmount) => {
  return Api.post(`/practices/${practiceId}/tickets/${ticketId}/buy`,  {variation_id: variationId, expired_at: expiredAt, custom_amount: customAmount});
}

export const subscribeToAddOn = (practiceId, addonId, startDate) => {
  return Api.post(`/practices/${practiceId}/addons/${addonId}/subscribe`,  {start_date: startDate});
}

export const generate2CheckoutUrl = (products) => {
  return Api.post('/2checkoutUrl', { products });
}