<template>
  <ListLayout>
    <template #header>
      <h2 class="data-table">All Admins List</h2>
      <div class="nav-button">
        <router-link to="/admin/new">
          <CallToActionButton> Add Admin</CallToActionButton>
        </router-link>
      </div>
    </template>
    <template #list>
      <DataTable v-if="items.length > 0" :titles="titles" :items="items" />
    </template>
  </ListLayout>
</template>

<script>
import { getAllAdmins } from "@/api/admins/adminsApi.js";

export default {
  name: "AdminsView",
  data() {
    return {
      items: [],
      titles: [{ id: "Id" }, { name: "Name" }, { email: "Email" }],
    };
  },

  async created() {
    this.items = await getAllAdmins();
  },
};
</script>

<style scoped>
.data-table {
  margin-bottom: 0;
}

.data-table {
  margin-bottom: 0;
}

.nav-button {
  text-decoration: none;
  margin-bottom: 5px;
}

.title-wrapper {
  justify-content: space-between;
  align-items: center;
  padding-right: 60px;
}
</style>
