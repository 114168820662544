<template>
  <v-card elevation="5">
    <v-table class="text-left" v-if="tickets && tickets.length > 0">
      <template #default>
        <thead>
          <tr>
            <th class="text-left">Name</th>
            <th class="text-left">Amount</th>
            <th class="text-left">Bought On</th>
            <th class="text-left">Expire At</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="ticket in tickets" :key="ticket.id">
            <td class="text-left">{{ticket.feature.name}}</td>
            <td class="text-left">{{ticket.amount}}</td>
            <td class="text-left">{{formatDate(ticket.created_at)}}</td>
            <td class="text-left">{{formatDate(ticket.expired_at)}}</td>
          </tr>
        </tbody>
      </template>
    </v-table>
    <v-card-text>
      <h4 v-if="tickets && tickets.length == 0"><i>Practice do not have Sms Packages!!</i></h4>
      <CallToActionButton class="ml-2">
        <BuySmsDialog 
          v-model:isOpen="showBuySmsDialog"
          :availableSms="availableSms"
          @subscribe="subscribe"
        ></BuySmsDialog>
        Add SMS Packages
      </CallToActionButton>
    </v-card-text>
  </v-card>
</template>

<script>

import { getPracticeTickets, getAvailablePracticeTickets, buyTicket } from "@/api/subscriptions/subscriptionsApi.js";

export default {
  props: {
    practiceId: {
      required: true,
    }
  },

  data() {
    return {
      tickets: null,
      availableSms: null,
      showBuySmsDialog: false,
      showAdditionErrorPopup: false,
    }
  },

  created() {
    this.getTickets();

    getAvailablePracticeTickets(this.practiceId).then(response => {
      this.availableSms = response.data.data;
    })
  },

  methods: {
    getTickets() {
      getPracticeTickets(this.practiceId).then(response => {
        this.tickets = response.data.data;
      })
    },

    subscribe(ticketId, variationId, expiredAt, customAmount) {
      buyTicket(this.practiceId, ticketId, variationId, expiredAt, customAmount).then(() => {
        this.getTickets();
      }).catch(() => {
        this.showAdditionErrorPopup = true;
      });
    },

    formatDate(dateString) {
      var options = { year: 'numeric', month: 'short', day: 'numeric' };
      return new Date(dateString).toLocaleDateString('en-us', options);
    },
  }

}
</script>