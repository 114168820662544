<template>
  <form
    action=""
    class="create-admin-form d-flex w-100 container align-items-center flex-column"
  >
    <div class="d-flex gap-2 w-100">
      <ControlledInputField
        inputName="First Name"
        inputFor="first-name"
        :errors="errors.firstName"
      >
        <input
          type="text"
          name="first-name"
          v-model="formData.firstName"
          placeholder="First Name"
        />
      </ControlledInputField>

      <ControlledInputField
        inputName="Last Name"
        inputFor="last-name"
        :errors="errors.lastName"
      >
        <input
          type="text"
          name="last-name"
          v-model="formData.lastName"
          placeholder="Last Name"
        />
      </ControlledInputField>
    </div>

    <ControlledInputField
      inputName="Email"
      inputFor="email"
      :errors="errors.email"
    >
      <input
        type="text"
        name="email"
        placeholder="Email"
        v-model="formData.email"
      />
    </ControlledInputField>

    <div class="d-flex gap-2 w-100">
      <ControlledInputField
        inputName="Password"
        inputFor="password"
        :errors="errors.password"
      >
        <input
          autocomplete="false"
          type="password"
          name="password"
          v-model="formData.password"
          placeholder="Password"
        />
      </ControlledInputField>

      <ControlledInputField
        inputName="Confirm Password"
        inputFor="password-confirmation"
        :errors="errors.passwordConfirmation"
      >
        <input
          autocomplete="false"
          type="password"
          name="password-confirmation"
          v-model="formData.passwordConfirmation"
          placeholder="Password Confirmation"
        />
      </ControlledInputField>
    </div>

    <ControlledInputField
      inputName="Practice Name"
      inputFor="practice-name"
      :errors="errors.practiceName"
    >
      <input
        type="text"
        name="practice-name"
        v-model="formData.practiceName"
        placeholder="Practice Name"
      />
    </ControlledInputField>
   
   <div class="d-flex w-100 custom-select" :class="{ error: errors && errors.practiceLanguage && errors.practiceLanguage[0] }">
    <label for="practice-language">Language: </label>
    <select name="practice-language" v-model="formData.practiceLanguage">
      <option
        v-for="language in availableLanguages"
        :key="language.iso_code"
        :value="language.iso_code">
        {{language.native_name}}
      </option>
    </select>
   </div>
    <small class="error-message">
      <div class="red text-left" v-for="(error, index) in errors.practiceLanguage" :key="index">
        {{ error }}
      </div>
    </small>

    <div class="d-flex gap-2 w-100">
      <ControlledInputField
        inputName="Country"
        inputFor="country"
        :errors="errors.country"
      >
        <input
          type="text"
          name="country"
          v-model="formData.country"
          placeholder="Country"
        />
      </ControlledInputField>

      <ControlledInputField
        inputName="Country Code"
        inputFor="country-code"
        :errors="errors.countryCode"
      >
        <input
          type="text"
          name="country"
          v-model="formData.countryCode"
          placeholder="Country Code"
        />
      </ControlledInputField>
    </div>
    <CallToActionButton
      :disabled="isLoading"
      class="mt-4"
      @buttonClick="handleSubmit"
    >
      Create New Practice
    </CallToActionButton>
  </form>
</template>

<script>
import { Validator } from "@/validation/validator";
import { createAccount } from "@/api/practices/practicesApi";
import { mapGetters } from "vuex";

export default {
  name: "CreateAccountForm",
  data() {
    return {
      formData: {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        passwordConfirmation: "",
        practiceName: "",
        country: "",
        countryCode: "",
        practiceLanguage: "",
      },

      errors: {
        firstName: null,
        lastName: null,
        email: null,
        password: null,
        passwordConfirmation: null,
        practiceName: null,
        country: null,
        countryCode: null,
      },
    };
  },
  methods: {
    async handleSubmit(event) {
      event.preventDefault();

      const { success, errors } = Validator.validate(this.formData, {
        firstName: "required",
        lastName: "required",
        email: "required|email",
        password: "required|min:8",
        passwordConfirmation: `required|min:8|equals:${this.formData.password}`,
        practiceName: "required",
        country: "required",
        countryCode: "required",
        practiceLanguage: "required",
      });

      this.errors = errors;

      if (success) {
        await createAccount({
          first_name: this.formData.firstName,
          last_name: this.formData.lastName,
          email: this.formData.email,
          password: this.formData.password,
          password_confirmation: this.formData.passwordConfirmation,
          practice_name: this.formData.practiceName,
          country: this.formData.country,
          country_code: this.formData.countryCode.toLowerCase(),
          language: this.formData.practiceLanguage,
        });
        this.$router.push("/practices");
      }
    },
  },

  computed: {
    ...mapGetters("loading", {
      isLoading: "getIfIsLoading",
    }),
    ...mapGetters("practice", {
      availableLanguages: "getAvailableLanguages"
    })
  },
};
</script>

<style scoped>
.create-admin-form {
  max-width: 600px;
}
.custom-select {
  border:1px solid #dfe6e9;
  height: 46px;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  background-color: #f3f4f5;
  border-radius: 10px;
  position: relative;
}
.custom-select select {
  width: 90%;
  cursor: pointer;
  padding: 0px 10px;
  height: 75%;
  text-align: center;
}
.error-message {
  color: #b85a6b !important;
  margin-left: 15px;
  min-height: 21px;
  width: 100%;
}
.red {
  color: var(--danger-color);
}

.error {
  background: var(--error-background-color) !important;
  border: 2px solid #e47e91 !important;
}
</style>