import { Api } from "../api";

export const createAdmin = (credentials) => {
  return Api.post("/admins", credentials);
};

export const getAllAdmins = () => {
  return Api.get("/admins").then((response) => {
    return response.data;
  });
};

export const deleteAdmin = (adminId) => {
  return Api.delete(`/admins/${adminId}`);
};
